// import web3 from "./../web3";

// //Check wallet available
// export const checkWalletAvailable = () => {
//   if (typeof window.ethereum !== "undefined") {
//     if (window.ethereum && window.ethereum.isMetaMask) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// };

// //Check correct network Id
// export const checkCorrectNetwork = async () => {
//   let chainID;
//   chainID = await web3.eth.getChainId();
//   return chainID;
// };

// //Get User Address from Web3
// export const getUserAddress = async () => {
//   const accounts = await window.ethereum.request({
//     method: "eth_requestAccounts",
//   });
//   const accountAddress = accounts[0];
//   return accountAddress;
// };

// // GET Main Token Balance
// export const getMainBalance = async () => {
//   let address = await getUserAddress();
//   let balance = await web3.eth.getBalance(address);
//   let mainBalance = web3.utils.fromWei(balance);
//   return mainBalance;
// };

import web3 from "./../web3";

// Check wallet available
export const checkWalletAvailable = () => {
  if (typeof window.ethereum !== "undefined") {
    return true;
  } else if (typeof window.web3 !== "undefined") {
    return true;
  } else {
    return false;
  }
};

// Check correct network Id
export const checkCorrectNetwork = async () => {
  try {
    let chainID;

    if (typeof window.ethereum !== "undefined") {
      chainID = await window.ethereum.request({ method: "eth_chainId" });
    } else if (typeof window.web3 !== "undefined" && window.web3.currentProvider) {
      chainID = await window.web3.currentProvider.send("eth_chainId");
    } else {
      throw new Error("Web3 provider not found.");
    }

    return chainID;
  } catch (error) {
    console.error("Error checking network ID: ", error);
  }
};

// Get User Address from Web3
export const getUserAddress = async () => {
  try {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      return accounts[0];
    } else if (typeof window.web3 !== "undefined" && window.web3.currentProvider) {
      const accounts = await window.web3.eth.getAccounts();
      return accounts[0];
    } else {
      throw new Error("Web3 provider not found.");
    }
  } catch (error) {
    console.error("Error getting user address: ", error);
  }
};

// GET Main Token Balance
export const getMainBalance = async () => {
  try {
    const address = await getUserAddress();
    let balance;

    if (typeof window.ethereum !== "undefined") {
      balance = await web3.eth.getBalance(address);
    } else if (typeof window.web3 !== "undefined" && window.web3.currentProvider) {
      balance = await web3.eth.getBalance(address);
    } else {
      throw new Error("Web3 provider not found.");
    }

    const mainBalance = web3.utils.fromWei(balance);
    return mainBalance;
  } catch (error) {
    console.error("Error getting main balance: ", error);
  }
};
