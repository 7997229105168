/* global BigInt */
import { useState } from "react";
import { useEffect } from "react";
import $ from 'jquery';
import {
  checkCorrectNetwork,
  checkWalletAvailable,
  getMainBalance,
  getUserAddress,
} from "./actions/Web3Actions";
import { multisendTokens, checkDRSApproval, burnFeeCheck, approveDRS, approveToken, checkDRSBalance, maxAddressLimitCheck,tokenDecimals } from "./actions/SmartActions";
import "./App.css";

import FAQSection from './FAQSection'; // Import the FAQSection component

const DRSAddress = "0x17637e738D095f4E480CabBF55038E4E9e2B235E";
function truncateAddress(address, startLength, endLength) {
  if (address.length <= startLength + endLength) {
    return address;
  }

  const start = address.slice(0, startLength);
  const end = address.slice(-endLength);
  return `${start}...${end}`;
}
var TAFIELD = "";



function App() {

  const [data, setData] = useState({
    wallet: false,
    chainId: "Unavailable",
    address: "Unavailable",
    balance: 0,
    DRSAllowance: 0,
    burnFee: 0,
    DRSBalance: 0,
  });
  const [addressLimit, setAddressLimit] = useState("");
  const [displayedBurnFee, setDisplayedBurnFee] = useState("");
  const [tokenDecimalsAmount, setTokenDecimalsAmount] = useState("");

  const [tokenAddress, setTokenAddress] = useState("");
  const [addresses, setAddresses] = useState("");
  const [amounts, setAmounts] = useState("");
  let [totalBatchesExternal,setTotalBatchesExternal] = useState(0);


  const connectButton = async () => {
    let wallet = await checkWalletAvailable();
    let address = await getUserAddress();
    let balance = await getMainBalance();
    let chainID = await checkCorrectNetwork();
    let DRSAllowance = await checkDRSApproval(address);
    let burnFee = await burnFeeCheck();
    let DRSBalance = await checkDRSBalance(address);
    setData({
      wallet: wallet,
      chainId: chainID,
      address: address,
      balance: balance,
      DRSAllowance: DRSAllowance,
      burnFee: burnFee,
      DRSBalance: DRSBalance,
    });
  };

  const setAndCheckTokenAddress = async (tokenAddress) => {
    let pattern = /^0x[a-fA-F0-9]{40}$/gm;
    let result = pattern.test(tokenAddress);
    if (result) {
      document.querySelector("#invalid-address").classList.remove("show");
      setTokenAddress(tokenAddress);
      let decimals = await tokenDecimals(tokenAddress);
      setTokenDecimalsAmount(decimals);
    } else {
      document.querySelector("#invalid-address").classList.add("show");
    }
    
  }
  
useEffect(() => {
  separateInput(TAFIELD);
}, [tokenDecimalsAmount])
  const multisendTokenstoUser = async () => {
    // console.log("tokenAddress "+tokenAddress);
    //   console.log("addresses "+addresses);
    //   console.log("amounts "+ amounts);
    //   console.log("fromaddress "+fromAdrress)
    // console.log(tokenAddress);
    // console.log(DRSAddress)
    $('#processingModal').removeClass('d-none');
    let fromAdrress = data.address;
    let totalBatches = BigInt(Math.ceil(addresses.length / addressLimit));
    try {
      const bigIntegers = amounts.map(str => BigInt(str));
      const sum = bigIntegers.reduce((total, num) => total + num, BigInt(0));

      if (tokenAddress != DRSAddress) {
        let approveDRSStatus = await approveDRS(BigInt(data.burnFee )* totalBatches, data.address);
          let approveTokenStatus = await approveToken(sum.toString(), tokenAddress, data.address);
       
        
      } else {
        let drsSum = BigInt(data.burnFee )* totalBatches  + sum
        await approveDRS(drsSum.toString(), data.address);
      }
      
      // let res = await multisendTokens(tokenAddress, addresses, amounts, fromAdrress);
      let count = 1;
      
      for (let i = 0; i < addresses.length; i += Number(addressLimit)) {
        $("#processing-text").text("Processing " + count + " of " + totalBatches);
        const addressesBatch = addresses.slice(i, i +Number(addressLimit));
        const amountsBatch = amounts.slice(i, i + Number(addressLimit));
        // console.log(addressesBatch);
        // console.log(amountsBatch);
        await multisendTokens(tokenAddress, addressesBatch, amountsBatch, fromAdrress);
        count++;
      }
      $("#processing-text").text("Processing");
      $('#processingModal').addClass('d-none');
      $("#successModal").removeClass("d-none");

    } catch (error) {
      $("#processing-text").text("Processing");
      $('#processingModal').addClass('d-none');
      $("#successModal").addClass("d-none");
      $("#errorModal").removeClass("d-none");

    }

  };
  const separateInput = (input) => {
    console.log("called");
    const lines = input.split('\n');
    const addresses = [];
    const amounts = [];

    lines.forEach((line) => {
      const trimmedLine = line.trim();

      if (trimmedLine.length === 0) {
        return;
      }
      const parts = trimmedLine.split(',');

      if (parts.length === 2) {
        document.querySelector("#invalid-format").classList.remove("show");
        addresses.push(parts[0].trim());
        if(tokenDecimalsAmount == 9){
          amounts.push(parts[1].trim() + "000000000");
        }else{
          amounts.push(parts[1].trim() + "000000000000000000");
        }
      
        // console.log(BigInt(parts[1].trim()) * Math.pow(10, tokenDecimalsAmount.length))
      } else {
        document.querySelector("#invalid-format").classList.add("show");
      }
    });
    
    setAddresses(addresses);
    setAmounts(amounts);
    if(addresses.length>0){
    setTotalBatchesExternal(BigInt(Math.ceil(addresses.length / addressLimit)));
    }
    console.log(amounts);
    console.log(addresses);
  }
  const updateWalletInfo = async () => {
    const wallet = await checkWalletAvailable();
    if (wallet) {
      let address = await getUserAddress();
    let balance = await getMainBalance();
    let chainID = await checkCorrectNetwork();
    let DRSAllowance = await checkDRSApproval(address);
    let burnFee = await burnFeeCheck();
    let DRSBalance = await checkDRSBalance(address);
      setData({
        wallet: wallet,
        chainId: chainID,
        address: address,
        balance: balance,
        DRSAllowance: DRSAllowance,
        burnFee: burnFee,
        DRSBalance: DRSBalance,
      });
    } else {
      // setData(null);
    }
  };
  const setMaxAddressLimit = async () => {
    let addressLimit = await maxAddressLimitCheck();
    setAddressLimit(addressLimit);
  }
  const setDisplayedBurnFeeFn = async () => {
    let f = await burnFeeCheck();
    setDisplayedBurnFee(f);
  }
  useEffect(() => {

    setDisplayedBurnFeeFn();
    setMaxAddressLimit();
    const handleAccountsChanged = async (accounts) => {
      
      if (accounts.length > 0) {
        updateWalletInfo();
        console.log("accounts changed"+data.address);
      } else {
        setData({
          wallet: false,
          chainId: "Unavailable",
          address: "Unavailable",
          balance: 0,
          DRSAllowance: 0,
          burnFee: 0,
          DRSBalance: 0,
        });
      }
    };

    window.ethereum.on("accountsChanged", handleAccountsChanged);

    return () => {
      window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
    };
  }, []);

  return (

    <div className="container-fluid">
      <div className="container">
        <div className="navigation-box">
          <div className="row justify-content-between align-items-center">
            <div><a href="https://daireflections.com/" target="_blank">
              <img className="drs-logo-img" src="images/drs_logo.png" />
            </a>
            </div>
            <div className="ms-nav-links d-none d-xl-block">
              <a href="https://t.me/daireflections">Community</a>
            </div>

            <div className="ms-nav-links d-none d-xl-block">
              <a href="https://bridge.pulsechain.com/#/bridge">ETH BRIDGE</a>
            </div>
            <div className="ms-nav-links d-none d-xl-block">
              <a href="https://daireflections.com/pulswap/">Swap from other chains</a>
            </div>
            <div className="button-container">
              {data.wallet ? (
                <button className="buttonMain">
                  {truncateAddress(data.address, 6, 4)}
                </button>
              ) : (
                <button onClick={connectButton} className="buttonMain">
                  <i class="fa-solid fa-wallet"></i> CONNECT
                </button>
              )}
            </div>

          </div>
        </div>
      </div>
      <div className="section">
        <div className="container text-center">
          <h2 className="main-title">Token Multisender</h2>
          <div className="d-flex ms-information justify-content-between align-items-center">
            <p>Fee: {displayedBurnFee.slice(0, -9)} DRS / {addressLimit} Addresses</p>
            <a className="buy-drs-link" target="_blank" href="https://app.pulsex.com/swap?outputCurrency=0x17637e738D095f4E480CabBF55038E4E9e2B235E">BUY $DRS</a>
            {
              data.wallet && BigInt(data.DRSBalance)>0? (
                <p className="drs-balance">DRS Balance: {data.DRSBalance.slice(0, -9)}</p>
              ):(<p className="drs-balance">DRS Balance: 0</p>)
            }

            

            {/* <p>Multisend limit: {addressLimit}</p> */}
          </div>

          <div className="multisend-box">
            <div>
              <h3 className="inner-title">Token Address</h3>
              <input
                type="text"
                onChange={(e) => { setAndCheckTokenAddress(e.target.value) }}
                placeholder="Token Contract Address You Want to Send"
                className="ms-address-input"
              />
              <p className="input-error d-none" id="invalid-address">INVALID ADDRESS: Please enter a valid address</p>
            </div>
            <div>
              <h3 className="inner-title">Amounts and Addresses</h3>
              <textarea
                className="ms-textarea"
                type="text"
                onChange={(e) => {
                  var addresses = e.target.value;
                  TAFIELD = addresses;
                  separateInput(addresses);
                }}
                placeholder="Wallet Addresses and Amount (Example)
                0xabCdeAbCdeAbCdeAbCdeAbCdeAbCdeAbCdeAbCde,25000
                0xabCdeAbCdeAbCdeAbCdeAbCdeAbCdeAbCdeAbCde,100"
              />
              <p className="input-error d-none" id="invalid-format">INVALID FORMAT: Please follow the correct format.</p>
            </div>

            <div className="send-btn">
              {data.wallet && BigInt(data.DRSBalance.slice(0, -9)) >= (BigInt(data.burnFee.slice(0, -9)) * BigInt(totalBatchesExternal)) && addressLimit * 100 >= addresses.length ? (
                <button onClick={multisendTokenstoUser} className="buttonBalance">
                  Send Tokens
                </button>
              ) : data.wallet && BigInt(data.DRSBalance.slice(0, -9)) < (BigInt(data.burnFee.slice(0, -9))*BigInt(totalBatchesExternal)) && addressLimit* 100 < addresses.length ? (
                <a href="https://app.pulsex.com/swap?outputCurrency=0x17637e738D095f4E480CabBF55038E4E9e2B235E" target="_blank" className="buttonBuy">
                  NOT ENOUGH $DRS ( BUY NOW <i class="fa-solid fa-arrow-up-right-from-square"></i> )
                </a>
              ) : data.wallet && BigInt(data.DRSBalance.slice(0, -9)) < (BigInt(data.burnFee.slice(0, -9))*BigInt(totalBatchesExternal)) && addressLimit * 100> addresses.length ? (
                <a href="https://app.pulsex.com/swap?outputCurrency=0x17637e738D095f4E480CabBF55038E4E9e2B235E" target="_blank" className="buttonBuy">
                  NOT ENOUGH $DRS ( BUY NOW <i class="fa-solid fa-arrow-up-right-from-square"></i> )
                </a>
              ): data.wallet && BigInt(data.DRSBalance.slice(0, -9)) >= (BigInt(data.burnFee.slice(0, -9))*BigInt(totalBatchesExternal)) && addressLimit * 100< addresses.length ? (
                <p className="limit-exceed">
                  Address Limit Exceeded
                </p>
              ) : (
                <button onClick={connectButton} className="buttonMain">
                  <i class="fa-solid fa-wallet"></i> CONNECT
                </button>
              )}

            </div>
          </div>
        </div>
      </div>

      <div id="processingModal" className="d-none" >
        <div class="modal-custom d-flex justify-content-center align-items-center flex-column">
          <button id="processing-close" className="close-btn" onClick={() => {
            $('#processingModal').addClass('d-none');
          }}>x</button>
          <p className="pt-3 text-center"><span id="processing-text">Processing</span><br /><span className="f-16">(Approve All Transactions)</span></p>
          <div class="host">
            <div class="loading loading-0"></div>
            <div class="loading loading-1"></div>
            <div class="loading loading-2"></div>
          </div>


        </div> <div className="mc-overlay"></div>
      </div>


      <div id="successModal" className="d-none">
        <div class="modal-custom d-flex justify-content-center align-items-center flex-column">
          <button id="success-close" className="close-btn" onClick={() => {
            $('#successModal').addClass('d-none');
          }}>x</button>
          <p>Success</p>
          <img src="images/tick.png" />

        </div>
        <div className="mc-overlay"></div>

      </div>

<div className="faq-container">
      <FAQSection /> {/* Include the FAQSection component here */}

      </div>

      <div id="errorModal" className="d-none">
        <div class="modal-custom d-flex justify-content-center align-items-center flex-column">
          <button id="error-close" className="close-btn" onClick={() => {
            $('#errorModal').addClass('d-none');
          }}>x</button>

          <p>Error: Transaction Failure</p>
          <img src="images/cross.png" /></div>
        <div className="mc-overlay"></div>

      </div>



    </div>
  );
}

export default App;
