import React from 'react';

const FAQSection = () => {
  const faqItems = [
    {
      question: 'Does it work with Reflections tokens?',
      answer: 'If you want to send Reflections token, you may encounter difficulties when sending batches of 100. Please reach out to our community at https://t.me/daireflections to address your specific needs!',
    },
    {
      question: 'Sender is not functioning.',
      answer: 'Please ensure that you have taken into account the use of both uppercase and lowercase characters in the contract address field. Sometimes this can be a source of issues. For instance, the correct way to paste the DRS contract address is: "0x17637e738D095f4E480CabBF55038E4E9e2B235E." Using the address in this way may lead to problems: "0x17637e738d095f4e480cabbf55038e4e9e2b235e."',
    },
    // Add more FAQ items as needed
  ];

  return (
    <div className="faq-section">
      <h3>Frequently Asked Questions</h3>
      <ul>
        {faqItems.map((item, index) => (
          <li key={index}>
            <div className="faq-question">{item.question}</div>
            <div className="faq-answer">{item.answer}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQSection;
